import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedListItemComponent } from './components/list-item/list-item.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { TopGroupListComponent } from './components/top-group-list/top-group-list.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';

@NgModule({
  declarations: [
    SharedListItemComponent,
    BackButtonComponent,
    TopGroupListComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule
  ],
  exports: [
    SharedListItemComponent,
    BackButtonComponent,
    TopGroupListComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreSharedModule { }
