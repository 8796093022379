import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { CoreSharedModule } from './shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreSharedModule,
    AkitaNgRouterStoreModule
  ],
  exports: [ CoreSharedModule ]
})
export class CoreModule { }
