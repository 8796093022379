import { Injectable, Injector } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { of, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { runStoreAction, StoreActions, createEntityQuery, createEntityStore } from '@datorama/akita';
import * as R from 'ramda';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  private instances = [];

  environment = environment;
  params;

  constructor(private http: HttpClient, private router: ActivatedRoute) {
    this.router.queryParams.subscribe((params) => {
      this.params = params;
    })
  }

  set({ store, endpoint, name, idKey = '_id', isCount = true, includeFields = null, limit = null, filters = null, sort = "_updated_at:DESC", appendQuery = null }) {
    
    this.instances.push({
      store: store,
      endpoint: endpoint,
      name: name,
      includeFields: includeFields,
      limit: limit,
      sort: sort,
      filters: filters,
      idKey: idKey,
      isCount: isCount,
      appendQuery: appendQuery
    })

    return this;
  }

  load(name, _start = 0, isReset = false) {
    const instance = R.findLast(R.propEq('name', name))(this.instances);
    if (instance) {
      let getCount = of(null);
      let url = `${instance.endpoint}?_start=${_start}`;
      let urlCount = `${instance.endpoint}/count?`;
      let appendUrl = '';

      if (instance.limit != null) {
        appendUrl += `&_limit=${instance.limit}`;
      }

      if (instance.sort != null) {
        appendUrl += `&_sort=${instance.sort}`;
      }

      if (instance.includeFields != null) {
        appendUrl += `&_inc=${instance.includeFields}`;
      }

      if (this.params != null && instance.filters != null && instance.filters.length > 0) {
        instance.filters.map((filter) => {
          if (this.params[filter]) {
            appendUrl += `&${filter}=${this.params[filter]}`;
          }
        })
      }
      
      if (instance.appendQuery && instance.appendQuery != null && instance.appendQuery != '') {
        appendUrl += `&${instance.appendQuery}`;
      }

      url += appendUrl;
      urlCount += appendUrl;

      if (instance.isCount) {
        getCount = this.http.get<any[]>(`${urlCount}`);
      }
      const all = this.http.get<any[]>(`${url}`);
      return forkJoin([
        getCount,
        all
      ]).pipe(
        tap(results => {
          if (results[0]) {
            runStoreAction(instance.name, StoreActions.Update, {
              payload: {
                data: { total: results[0], _start: _start }
              }
            });
          }
          if (isReset) {
            runStoreAction(instance.name, StoreActions.SetEntities, {
              payload: {
                data: results[1]
              }
            });
          } else {
            runStoreAction(instance.name, StoreActions.UpsertEntities, {
              payload: {
                data: results[1]
              }
            });
          }
        })
      )
    }
  }

  loadMore(name) {
    const instance = R.findLast(R.propEq('name', name))(this.instances);
    if (instance) {
      let _start = createEntityQuery(instance.store).getValue()._start;
      if (instance.limit != null) {
        _start += instance.limit;
      }
      return this.load(name, _start);
    }
  }

  loadPaging(name, page) {
    const instance = R.findLast(R.propEq('name', name))(this.instances);
    if (instance) {
      let _start = createEntityQuery(instance.store).getValue()._start;
      if (instance.limit != null) {
        _start += (page - 1) * instance.limit;
      }
      return instance.load(name, _start, true);
    }
  }

  getInstanceStore(name) {
    const instance = R.findLast(R.propEq('name', name))(this.instances);
    return instance.store;
  }

  getInstanceQuery(name) {
    const instance = R.findLast(R.propEq('name', name))(this.instances);
    return createEntityQuery(instance.store);
  }
}