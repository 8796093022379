import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { debounceTime } from 'rxjs/operators';

import * as localForage from 'localforage';
import 'hammerjs';

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'TechZ',
  version: 1.0,
  storeName: 'techz-storage'
});

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

persistState({
  storage: localForage,
  preStorageUpdateOperator: () => debounceTime(2000)
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
