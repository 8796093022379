import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable } from "rxjs";
import { share } from "rxjs/operators";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  someDataObservable: Observable<any>;

  constructor() { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let httpHeader = new HttpHeaders();
    httpHeader = httpHeader.set('key', 'technewsvn');

    req = req.clone({
      headers: httpHeader
    });
    
    if (req.method == 'GET') {
      this.someDataObservable = next.handle(req).pipe(share());
    }

    return next.handle(req);
  }
}