import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-group-list',
  templateUrl: './top-group-list.component.html',
  styleUrls: ['./top-group-list.component.scss'],
})
export class TopGroupListComponent implements OnInit {

  @Input() items = [];

  Object = Object;
  isProcessDone = false;
  itemsProcessed = [];

  constructor() { }

  ngOnInit() {
    const groupBy = (objectArray, property) => {
      return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    };

    if (this.items && this.items.length > 0) {
      const _temps = groupBy(this.items, 'category');
      for (var property in _temps) {
        if (_temps.hasOwnProperty(property)) {
          this.itemsProcessed.push({
            'category': property,
            'data': _temps[property]
          })
        }
      }
      this.isProcessDone = true;
    }
  }

  ngAfterViewInit() {
  }

}
