import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class SharedListItemComponent implements OnInit {

  @Input() src;
  @Input() title;
  @Input() desc;
  @Input() slot = 'start';

  constructor() { }

  ngOnInit() {}

}
