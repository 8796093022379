import { Mode } from '@ionic/core/dist/types/interface';
const mode: Mode = 'md';

export const appSetting = {
  name: "TechZ",
  lang: 'vi',
  mode: mode,
  api: {
    limit: 10
  },
  tabs: [
    { "name": "Home", "tab": 'home', 'icon': '/assets/icon/home.svg', 'icon-name': null, params: null },
    { "name": "Explore", "tab": 'posts', 'icon': '/assets/icon/compass.svg', 'icon-name': null, params: null },
    { "name": "Bookmark", "tab": 'map', 'icon': '/assets/icon/bookmark.svg', 'icon-name': null, params: null },
    { "name": "Profile", "tab": 'about', 'icon': '/assets/icon/user.svg', 'icon-name': null, params: null }
  ]
}

export interface _Module {
  name: string;
  displayTabs: boolean;
  layout: string;
  setting?: any;
}

export interface _ModuleSetting {
  modules: _Module[];
}

export interface Style {
  padding?: boolean;
}

export interface _Component {
  name: string;
  isDisabled: boolean;
  storeName?: string;
  style?: Style,
  slot: string; // header, content, footer
}

export interface Store {
  name: string;
  endpoint: string;
  appendQuery?: string;
  resettable: boolean;
  isForceReset: boolean;
  idKey: string;
  limit: number;
  filters: any[];
  sort: string;
  includeFields: any[];
  isCount: boolean;
  isSetEntity?: boolean;
}

export interface _Setting {
  components: _Component[];
  stores: Store[];
}

export interface StyleOne extends _Setting {
  isHeader?: boolean;
  title?: string;
}

export interface List extends _Setting {
  isHeader?: boolean;
  title?: string;
}

export interface Detail extends _Setting {
  isHeader?: boolean;
  title?: string;
}