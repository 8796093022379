import { Component, ViewChild, Renderer2 } from '@angular/core';
import { IonTabs } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationEnd } from '@angular/router';
import { appSetting } from '../environments/interface';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import * as R from 'ramda';
import { runStoreAction, StoreActions, createQuery } from '@datorama/akita';
import { appStore } from '@core/services/appStore';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  // displayTabs$;
  tabs = R.clone(appSetting.tabs);

  @ViewChild('mainTabs') mainTabs: IonTabs;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private routerQuery: RouterQuery,
    private renderer: Renderer2,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // this.router.navigate(['/home']);
      createQuery(appStore);
      // this.displayTabs$ = query.select('displayTabs');
    });
  }

  ngOnInit() {
  }

  ionTabDidChange(event) {
    setTimeout(() => {
      this.tabs.map((_tab, index) => {
        const _tmpTab = _tab.tab.split('?')[0].replace('/', '');
        if (event.tab == _tmpTab) {
          this.renderer.addClass(document.getElementsByClassName('main-tabs__button')[index], 'tab-selected');
        } else {
          this.renderer.removeClass(document.getElementsByClassName('main-tabs__button')[index], 'tab-selected');
        }
      });
    }, 100);
  }

  tabClick(tab) {
    let _url = this.router.url.split('?')[0];
    _url = _url.replace('/', '');

    this.tabs.map((_tab) => {
      const _tmpTab1 = _tab.tab.split('?')[0].replace('/', '');
      const _tmpTab2 = tab.tab.split('?')[0].replace('/', '');
      if (_tmpTab1 == _tmpTab2) {
        _tab.selected = true;
      } else {
        _tab.selected = false;
      }
      if (_url == _tmpTab1) {
        _tab.tab = this.router.url;
      }
      return _tab;
    });
    const index = R.findIndex(R.propEq('tab', tab.tab))(this.tabs);
    this.router.navigateByUrl(this.tabs[index].tab);
  }

  doSomething(tab) {
    runStoreAction('appSetting', StoreActions.Update, {
      payload: {
        data: { isScrollingToTop: true }
      }
    });
  }
}
