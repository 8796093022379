import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ThemesModule } from '@themes/themes.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from '../interceptors/http.interceptor';

import { EntityService } from '../app/core/services/entity.service';

import { NgxProgressiveImgLoaderModule } from 'ngx-progressive-img-loader';
import { appSetting } from '@env/interface';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';

import { CoreModule } from '@core/core.module';
import { DoubleTapDirective } from './directives/double-tap.directive';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    'press': {time: 500},  // default: 251 ms
    'pinch': {enable: false},
    'rotate': {enable: false},
  };
}

@NgModule({
  declarations: [AppComponent, DoubleTapDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    CoreModule,
    ThemesModule.forRoot(),
    IonicModule.forRoot({
      mode: appSetting.mode,
      swipeBackEnabled: true,
    }),
    AkitaNgRouterStoreModule,
    AppRoutingModule,
    HttpClientModule,
    NgxProgressiveImgLoaderModule,
  ],
  providers: [
    StatusBar,
    EntityService,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
