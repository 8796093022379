import { Component, Input, TemplateRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-style-one',
  templateUrl: './style-one.component.html',
  styleUrls: ['./style-one.component.scss'],
})
export class StyleOneComponent implements OnInit {

  @Input() isHeader: boolean = true;
  @Input() title: string;
  @Input() topCategories: boolean = true;

  constructor() { }

  ngOnInit() {}

}
