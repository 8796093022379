import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { runStoreAction, StoreActions, createQuery } from '@datorama/akita';
import { appStore } from '@core/services/appStore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-layout',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {

  @Input() isHeader: boolean = true;
  @Input() title: string;
  @Input() refresher;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  @Output() scrollToTop: EventEmitter<any> = new EventEmitter();

  isScrollingToTop$;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    const query = createQuery(appStore);
    if (this.isScrollingToTop$) this.isScrollingToTop$.unsubscribe();
    this.isScrollingToTop$ = query.select((state:any) => state.isScrollingToTop).subscribe((isScrollingToTop) => {
      if (isScrollingToTop) {
        this.content.scrollToTop(500);
      }
      runStoreAction('appSetting', StoreActions.Update, {
        payload: {
          data: { isScrollingToTop: false }
        }
      });
    });
    this.scrollToTop.emit(this.content);
  }

  ngOnDestroy() {
    if (this.isScrollingToTop$) this.isScrollingToTop$.unsubscribe();
  }

}
