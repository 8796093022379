import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CarouselModule } from '../../../shared/stack-detal/esm2015/lib/carousel/carousel.module.js';
import { TopCategoriesModule } from '../../../shared/stack-detal/esm2015/lib/top-categories/top-categories.module';
import { CategorySegmentModule } from '../../../shared/stack-detal/esm2015/lib/category-segment/category-segment.module';
import { CoreModule } from '@core/core.module';

import {
  StyleOneComponent,
  ErrorComponent,
  CategoryComponent,
  PostComponent,
} from './layouts';

const MODULES = [
  CarouselModule,
  TopCategoriesModule,
  CategorySegmentModule,
  CoreModule,
]

const COMPONENTS = [
  StyleOneComponent,
  ErrorComponent,
  CategoryComponent,
  PostComponent,
];

@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    IonicModule,
    ...MODULES
  ],
  exports: [CommonModule, CoreModule, ...COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ThemesModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemesModule,
      providers: [],
    };
  }
}
